.outerHeaderWrapper {
  position: fixed !important;
  background-color: #fff;
  width: 100%;
  z-index: 2;
}
.headerImg {
  width: 100%;
}
.header-wrapper {
  box-shadow: 0 1px 25px 1px #000 !important;
  display: flex;
  align-items: center;
}
.header-wrapper .item {
  color: #000 !important;
}
.tools-search-wrapper {
  display: none !important;
}
.header-wrapper .ui.secondary.pointing.menu .active.item {
  border-color: rgba(30, 92, 122, 0.7);
}
.header-wrapper .ui.secondary.pointing.menu .active.item:hover {
  border-color: #202B3A;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #202B3A !important;
}
.public-ui .content-area {
  padding-top: 12em;
}
.view-editview .outerHeaderWrapper,
.view-addview .outerHeaderWrapper,
.view-contentsview .outerHeaderWrapper,
.section-controlpanel .outerHeaderWrapper {
  position: unset !important;
}
