.rangeContainer {
  margin: 4em 0;
}
.rangeContainer p {
  font-size: 1.1em;
}
.range-slider {
  display: flex;
  margin: 1em 0;
}
.box-minmax {
  margin-top: 0.5em;
  width: 81%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
}
.box-minmax span:first-child {
  margin-left: 1%;
}
.rs-range {
  width: 80%;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.rs-range:focus {
  outline: none;
}
.rs-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: #000;
  border-radius: 0px;
  border: 0px solid #010101;
}
.rs-range::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: #000;
  border-radius: 0px;
  border: 0px solid #010101;
}
.rs-range::-webkit-slider-thumb {
  border: none;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background: #1e5c7a;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: -0.75em;
}
.rs-range::-moz-range-thumb {
  border: none;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background: #1e5c7a;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: -0.75em;
}
.rs-range::-moz-focus-outer {
  border: 0;
}
.rs-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  margin-left: 2em;
  font-size: 1.5em;
}
@media only screen and (min-width: 600px) {
  .rs-range {
    width: 50%;
  }
  .box-minmax {
    width: 51%;
  }
  .rs-label {
    margin-left: 5em;
  }
}
